const indentLines = (string, spaceCount) => {
  const spaces = " ".repeat(spaceCount)

  return string
    .split(/\r?\n/)
    .map(line => (line === "" ? "" : spaces + line))
    .join("\n")
}

export default indentLines
