import { imageOrVideoProjection } from "../../vendor/hoa/image_or_video/projections"

export const heroProjection = `{
  _type,
  subject,
  title,
  description,
  scrollNotice,
  imageOrVideo ${imageOrVideoProjection},
}`
