import { bannerProjection } from "../../vendor/hoa/banner/projections"
import { blockContentProjection } from "../../vendor/hoa/rich_text/projections"
import { calendlyProjection } from "../../components/calendly/projection"
import { gridProjection } from "../../vendor/hoa/grid/projections"
import { imageByTextProjection } from "../../vendor/hoa/image_by_text/projections"
import { imageMarqueeProjection } from "../../vendor/hoa/image_marquee/projections"
import { imageProjection } from "../../vendor/hoa/sanity_image/projections"
import { internalLinkProjection } from "../../vendor/hoa/links/projections"
import { muxVideoProjection } from "../../vendor/hoa/mux_video/projections"
import { socialItemProjection } from "../../components/social/social-items-projection"
import { heroProjection } from "../../components/hero/hero-projection"
import { sectionProjection } from "../../components/section/section-projection"

import indentLines from "../indentLines"
import { carouselProjection } from "../../components/carousel/carousel-projection"

const plpProductProjection = `{
  _id,
  customInventoryType,
  customInventoryKlaviyoListId,
  handle,
  id,
  path,
  "plpImage": plpImage.asset -> ${imageProjection},
  "plpImageHover": plpImageHover.asset -> ${imageProjection},
  priceRange,
  tags,
  title,
  variants[] { compareAtPrice },
}`

const seoProjection = `{
  description,
  "imageUrl": image.asset -> url,
}`

const shopifyCollectionProjection = `{
  ...,

  products[] -> ${plpProductProjection},
}`

const pageComponentsProjection = `{
  ...,

  image._type == "image" => {
    "image": image.asset -> ${imageProjection},
  },

  _type == "Banner" => ${bannerProjection},

  _type == "Bio" => {
    items[] {
      ...,
      description[] ${indentLines(blockContentProjection, 8)},
      "image": image.asset -> ${imageProjection},
    },
  },

  _type == "Calendly" => ${calendlyProjection},

  _type == "FAQ" => {
    items[] {
      ...,
      answer[] ${indentLines(blockContentProjection, 8)},
    },
  },

  _type == "Grid" => ${gridProjection},

  _type == "Hero" => ${heroProjection},

  _type == "ImageByText" => ${imageByTextProjection},

  _type == "ImageByTextPortrait" => {
    content[] ${indentLines(blockContentProjection, 6)},
    title[] ${indentLines(blockContentProjection, 6)},
  },

  _type == "ImageMarquee" => ${imageMarqueeProjection},

  _type == "ItemRow" => {
    items[] {
      ...,
      "image": image.asset -> ${imageProjection},
    },
  },

  _type == "RichText" => {
    content[] ${indentLines(blockContentProjection, 6)},
  },

  _type == "SubNav" => {
    subNav -> {
      ...,

      items[] {
        ...,

        document -> ${internalLinkProjection},
      },
    },
  },

  _type == "VideoPlayer" => {
    "video": video.asset -> ${muxVideoProjection},
  },

  _type == "Section" => ${sectionProjection},

  _type == "Carousel" => ${carouselProjection}
}`

export const COLLECTION_PROJECTION = `{
  ...,

  bottomComponents[] ${pageComponentsProjection},
  components[] ${pageComponentsProjection},
  shopifyCollection -> ${indentLines(shopifyCollectionProjection, 2)},

  subCollections[] {
    ...,

    bottomComponents[] ${pageComponentsProjection},
    components[] ${pageComponentsProjection},
    shopifyCollection -> ${indentLines(shopifyCollectionProjection, 4)},
  },

  seo ${seoProjection},
}`

export const PAGE_PROJECTION = `{
  ...,

  components[] ${pageComponentsProjection},
  seo ${seoProjection},
}`

export const PRODUCT_PROJECTION = `{
  ...,

  bottomComponents[] ${pageComponentsProjection},

  pdpContent[] {
    ...,

    _type == "supplementPdp" => {
      benefitsContent[] ${indentLines(blockContentProjection, 6)},

      benefitsItems[] {
        ...,
        "image": image.asset -> ${imageProjection},
      },

      insideContent[] ${indentLines(blockContentProjection, 6)},

      "insideImage": insideImage.asset -> ${imageProjection},

      nutritionLabels[] {
        ...,
        "image": image.asset -> ${imageProjection},
      },
    },
  },

  pdpImages[] {
    ...,
    "image": image.asset -> ${imageProjection},
  },

  "plpImage": plpImage.asset -> ${imageProjection},

  "rechargeProduct": *[
    _type == "rechargeProduct" &&
    shopifyProductId == ^.legacyResourceId
  ][0],

  "relatedProducts": coalesce(
    *[
      _type == "defaultRelatedProducts" &&
      ^._id in matchingProducts[]._ref
    ][0].relatedProducts,
    *[
      _type == "defaultRelatedProducts" &&
      ^.productType in productTypes
    ][0].relatedProducts
  ) {
    ...,
    products[] -> ${plpProductProjection},
  },

  seo ${seoProjection},

  "video": video.asset -> ${muxVideoProjection},
}`

export const RECHARGE_PRODUCT_PROJECTION = `{ _id, discountAmount, discountType, shopifyProductId }`

const navItemProjection = (depth = 0) => {
  const nestedItemsProjection =
    depth > 0 ? `items[] ${navItemProjection(depth - 1)}` : `"items": null`

  return `{
    ...,

    _type == "collectionNavItem" => {
      collection -> {
        path,

        "collectionItem": {
          "_type": "internalLinkNavItem",
          "name": "All",
          "document": {
            "path": path,
          },
        },

        "subCollectionItems": subCollections[] {
          _key,
          "_type": "internalLinkNavItem",
          "name": title,
          "document": {
            "path": {
              "current": ^.path.current + "/" + shopifyCollection -> handle
            },
          },
        },
      },
    },

    _type == "internalLinkNavItem" => {
      document -> ${internalLinkProjection},
    },

    _type in ["footerNestedNavItem", "nestedNavItem"] => {
      ${nestedItemsProjection},
    },

    _type == "footerNestedNavItem" => {
      headingLink[] {
        ...,
        document -> ${internalLinkProjection},
      }[0],
    },
  }`
}

export const CMS_GLOBALS_PROJECTION = `{
  ...,

  _type == "memberCenterSettings" => {
    pageComponents[] {
      ...,
      components[] ${pageComponentsProjection},
    },
  },

  _type == "navigation" => {
    desktopLeftItems[] ${navItemProjection()},
    desktopRightItems[] ${navItemProjection()},
    footerItems[] ${navItemProjection(4)},
    mobileItems[] ${navItemProjection(4)},
    legalItems[] ${navItemProjection()},
    socialItems[] ${socialItemProjection},
  },

  _type == "siteSettings" => {
    "authenticationPageImage": authenticationPageImage.asset -> ${imageProjection},
    emptyCartLink -> ${internalLinkProjection},
    "favicon": favicon.asset -> ${imageProjection},
    guarantee[] ${indentLines(blockContentProjection, 2)},
    prop65Page -> ${internalLinkProjection},
  },
}`
