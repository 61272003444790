import PropTypes from "prop-types"
import React from "react"
import PageComponents from "./PageComponents"

const Page = ({ page }) => <PageComponents components={page.components} />

Page.propTypes = {
  page: PropTypes.object.isRequired,
}

export default Page
