import { gridProjection } from "../grid/grid-projections"
import { horizontalContentProjection } from "../horizontal-content/horizontal-content-projection"
import { textProjection } from "../text/text-projection"

export const sectionProjection = `{
  content[] {
    ...,
    _type == "PureGrid" => ${gridProjection},
    _type == "Text" => ${textProjection},
    _type == "HorizontalContent" => ${horizontalContentProjection}
  }
}`
