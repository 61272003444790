import { imageProjection } from "../../vendor/hoa/sanity_image/projections"
import { buttonProjection } from "../buttons/buttons-projections"

export const gridCellProjection = `{
  ...,
  content[] {
    ...,
    _type == "Image" => {
      ...,
      "image": image.asset -> ${imageProjection},
    },
    ${buttonProjection}
  },
}`

export const gridProjection = `{
  _key,
  _type,
  className,
  numberOfCellsDesktop,
  numberOfCellsTablet,
  numberOfCellsMobile,
  verticalAlign,
  horizontalAlign,
  content[] {
    ...,
    _type == "GridCell" => ${gridCellProjection}
  }
}`
