import React, { useEffect, useState } from "react"
import {
  COLLECTION_PROJECTION,
  PAGE_PROJECTION,
  PRODUCT_PROJECTION,
} from "../lib/cms/projections"
import Layout from "../components/Layout"
import NotFound from "../components/NotFound"
import Page from "../components/Page"
import ProductPage from "../components/products/ProductPage"
import { isPreviewEnabled, loadPreviewDeps } from "../lib/livePreview"
import CollectionPage from "../components/collections/CollectionPage"
import { trackPageType } from "../lib/tracking/tracking"

const previewTypes = {
  collection: {
    projection: COLLECTION_PROJECTION,
    render(collection) {
      return <CollectionPage collection={collection} />
    },
  },

  page: {
    projection: PAGE_PROJECTION,
    render(page) {
      return <Page page={page} />
    },
  },

  product: {
    projection: PRODUCT_PROJECTION,
    render(product) {
      return <ProductPage product={product} />
    },
  },
}

const fetchPreview = async () => {
  const { createSanityClient, fetchPreview } = await loadPreviewDeps()

  const previewClient = createSanityClient({
    dataset: process.env.GATSBY_SANITY_DATASET,
    projectId: process.env.GATSBY_SANITY_PROJECT_ID,
  })

  const docType = await previewClient.fetch(
    "*[path.current == $path][0]._type",
    { path: window.location.pathname }
  )

  if (docType) {
    const { projection, render } = previewTypes[docType]

    const [previewDoc] = await fetchPreview(previewClient, {
      params: { path: window.location.pathname },
      prefetchedResults: [],
      query: ["*[path.current == $path]", projection].join("|"),
      sanityConfig: {},
    })

    return render(previewDoc)
  }
}

const NotFoundPage = () => {
  const [previewComponent, setPreviewComponent] = useState()

  useEffect(() => {
    trackPageType("not-found")
  }, [])

  useEffect(async () => {
    if (isPreviewEnabled()) {
      setPreviewComponent(await fetchPreview())
    }
  }, [])

  return <Layout>{previewComponent || <NotFound />}</Layout>
}

export default NotFoundPage
